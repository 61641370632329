import { HydraLogo } from '~/assets/HydraLogo';
import { FormSubmitButton } from '~/components/form-submit-button';
import { ValidatedForm, Validator } from 'remix-validated-form';
import React from 'react';
import { Button } from '~/components/ui/button';

interface AuthContainerProps {
  buttonLabel: string;
  buttonType: 'submit' | 'button';
  contentTitle: string;
  contentDescription?: string | React.ReactNode;
  children: React.ReactNode;
  form?: boolean;
  footer?: React.ReactElement<{ className?: string }>;
  formValidator?: Validator<any>;
  onButtonClick?: () => void;
}

const withForm = ({
  form,
  formValidator,
  children,
}: Partial<AuthContainerProps>) => {
  const className = 'flex flex-col justify-center h-full';
  return form && formValidator ? (
    <ValidatedForm
      validator={formValidator}
      method="post"
      className={className}
    >
      {children}
    </ValidatedForm>
  ) : (
    <div className={className}>children</div>
  );
};

export function AuthContainer({
  buttonLabel,
  buttonType,
  contentTitle,
  contentDescription,
  children,
  footer,
  form = true,
  formValidator,
  onButtonClick,
}: AuthContainerProps) {
  const ButtonComponent = buttonType === 'submit' ? FormSubmitButton : Button;

  const FooterComponent = () =>
    React.isValidElement(footer) &&
    React.cloneElement(footer, {
      className: footer?.props?.className + ' mt-8',
    });

  return (
    <div className="bg-[#231F37] h-full flex flex-col rounded-r-xl">
      <h1 className="text-2xl">{contentTitle}</h1>
      {typeof contentDescription === 'string' ? (
        <p className="text-[#9394BA] mt-10 mb-7 text-sm">
          {contentDescription}
        </p>
      ) : (
        contentDescription
      )}
      {withForm({
        form,
        formValidator,
        children: (
          <>
            <div className="flex flex-grow flex-col justify-center mb-10">
              {children}
            </div>
            <ButtonComponent
              label={buttonLabel}
              className="mt-auto"
              onClick={onButtonClick}
            >
              {buttonLabel}
            </ButtonComponent>
          </>
        ),
      })}
      <FooterComponent />
    </div>
  );
}
